<template>
  <div style="">
    <video
      height="80%"
      ref="videoPlayer"
      class="video-js"
      data-setup='{"fluid": true}'
    ></video>
  </div>
</template>

<style>
/* include video-js style */
@import "~video.js/dist/video-js.css";

video {
  border-bottom: 1px solid transparent;
  background-color: transparent;
  background: transparent;
}
/* custom style */
.video-js {
  /* width: 100%; */
  /* aspect-ratio: 16 / 9; */
}
.v-dialog--active {
  box-shadow: none;
}
</style>

<script>
import videojs from "video.js";

export default {
  name: "VideoPlayer",
  props: {
    paused: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      player: null,
    };
  },
  mounted() {
    this.player = videojs(this.$refs.videoPlayer, this.options, () => {
      this.player.log("onPlayerReady", this);
    });
    if (this.paused) {
      this.player.pause();
    } else {
      this.player.play();
    }
  },
  watch: {
    options: {
      deep: true,
      handler(newVal) {
        this.player.reset();
        this.player.src(newVal.sources);
        this.player.load();
      },
    },
    paused(newVal) {
      if (newVal) {
        this.player.pause();
      } else {
        this.player.play();
      }
    },
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>
