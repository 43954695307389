import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import Tracks from '@/components/Tracks.vue'

Vue.use(Vuetify);

export default new Vuetify({
  // dark: true,
  icons: {
    values: {
      tracks: { // name of our custom icon
        component: Tracks, // our custom component
      },
    },
  },
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: "#1976D2", //default for buttons!
        background:"#141518", //background of the app!
        
      },
      light: {
        field: "#2C2F34",
      },
    },
  },
});

// const vuetify = new Vuetify({
//   theme: {
//     themes: {
//       light: {
//         primary: "#1976D2",
//         secondary: "#424242",
//         accent: "#82B1FF",
//         error: "#FF5252",
//         info: "#2196F3",
//         success: "#4CAF50",
//         warning: "#FFC107",
//         field: "#2C2F34",
//         background:"#141518",
//         buttontext: "#FFFFFF",
//         nav: "#303030",
//       },
//     },
//   },
// })

// export default vuetify