import { RouteConfig } from "vue-router";

export default [
  {
    path: "/checkout/:plan?",
    name: "checkout",
    component: () =>
      import(/* webpackChunkName: "checkout" */ "../views/CheckoutView.vue"),
    meta: {
      title: "Checkout",
      requiresAuth: true,
    },
  },
  {
    path: "/register",
    name: "Register",
    meta: {
      title: "Register",
    },
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/RegisterView.vue"),
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      title: "Login",
    },
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginView.vue"),
  },
  {
    path: "/reset",
    name: "Reset Password",
    meta: {
      title: "Reset Password",
    },
    component: () =>
      import(/* webpackChunkName: "reset" */ "../views/ResetView.vue"),
  },
] as RouteConfig[];
