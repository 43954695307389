import {
  Client,
  User,
  Annuity,
  Snapshot,
  AnnuityConversionInputs,
} from "@/models";
import Vue from "vue";
import Vuex from "vuex";
import { db } from "@/firebaseConfig";
import { collection, query, where, getDocs } from "firebase/firestore";

Vue.use(Vuex);

export class Question {
  constructor(
    public component: string,
    public has_back: boolean = true,
    public question_prompt: string = "not_implemented",
    public secondary_validation: Function = function (
      quiz_state: typeof store.state.quiz_state
    ) {
      return true;
    }
  ) {}
}

const store = new Vuex.Store({
  state: {
    requested_route: null as string | null,
    _advisors: [] as User[],
    _advisor_clients: {} as { [key: string]: Client[] },
    create_seminar: {} as any,
    cart: {
      plan: null as string | null,
      been_to_pricing: null as boolean | null,
    },
    enterprise_view_tab: "policies",
    enterprise_teams_tab: "create-teams",
    enterprise_filtered_agent_name: [] as any[] | null,
    enterprise: {
      agents_list: [] as any[] | null,
      marketers: null as any[] | null,
      agents: null as any[] | null,
      policies: null as any[] | null,
      panel: null as any[] | null,
      // api_url: "http://localhost:8000",
      api_url: "https://admin.annuityos.com",
    },
    snackbar: {
      show: false,
      text: "",
      timeout: 5000,
    },
    _users_loading: true,
    _users: [] as User[],
    user_loading: null as Promise<any> | null,
    contracts_loading_p: null as Promise<any> | null,
    _clients_loading: true,
    _clients: [] as Client[],
    _annuities_loading: true,
    _annuities: [] as Annuity[],
    _snapshots_loading: true,
    _snapshots: [] as Snapshot[],
    _morningstar_loading: true,
    _morningstar: [] as any[],
    user: {
      is_login: false,
      acl: 0,
      uid: "",
      first_name: "",
      last_name: "",
      email: "",
    } as User,
    new_annuity_quiz: [
      new Question("ClientQuestion", false),
      new Question("CurrentAnnuityQuestion"),
      new Question("FeesAndChargesQuestion"),
      new Question("KeyDatesQuestion"),
      new Question("CurrentAnnuityDataQuestion"),
      // new Question("SnapshotQuestion"),
      new Question("NewAnnuityQuizComplete"),
    ],
    client_update_annuity_quiz: [] as Question[],
    add_another_annuity_quiz: [] as Question[],
    quiz_meta: {
      current_question: 0,
      current_quiz: "new_annuity_quiz",
    },
    conversion_state: {
      account_value: 0 as number | null,
      free_withdrawal_amount: 0 as number | null,
      surrender_value: 0 as number | null,
      bonus_percentage: 0 as number | null,
      wd_type: "" as string | null,
      has_income_account: false,
      income_account_value: null as number | null,
      has_death_benefit: false,
      death_benefit_amount: null as number | null,
    } as AnnuityConversionInputs,
    quiz_state: {
      // Page 1 - Client Questions
      first_name: "",
      last_name: "",
      dob: "",
      email: "",
      cid: "",
      uid: "",
      phone: "",
      state: "",
      notes: "",

      _dob_radio: "dob",
      _dob_day: "",
      _dob_month: "",
      _dob_year: "",
      _select_or_create: "select",

      // Page 2 - Carrier & Product Data
      carrier: "",
      product: "",
      annuity_type: "Non-Qualified",
      asset_type: "va",
      policy_number: "",

      // Page 3 - Fees & Charges
      guaranteed_income: false,
      has_death_benefit: false,
      me_fee: "",
      admin_fee: "",
      expense_ratio: "",
      income_rider_fee: "",
      other_fee: "",
      income_start_age: "",
      income_rider_amount: "",

      // Page 4 - Key Dates
      statement_date: "",
      _statement_day: "",
      _statement_month: "",
      _statement_year: "",
      issue_date: "",
      _issue_day: "",
      _issue_month: "",
      _issue_year: "",
      surrender_years: "",

      // Page 5 - Deposits & Withdrawals
      initial_deposit: null as number | null,
      total_additional_deposits: 0 as number | null,
      total_withdrawals: 0 as number | null,

      // Page 6 - Account Values
      current_value: null as number | null,
      mva: false,
      surrender_value: null as number | null,

      // new-statement
      additional_deposits: null as number | null,
      withdrawals: null as number | null,
    },

    ethnicityChanged: false,
    ethnicityList: [
      { selected: true, text: "African American", value: "A" },
      { selected: true, text: "East Asian", value: "B" },
      { selected: true, text: "Central & Southwest Asian", value: "D" },
      { selected: true, text: "Mediterranean", value: "E" },
      { selected: true, text: "Native American", value: "F" },
      { selected: true, text: "Scandinavian", value: "G" },
      { selected: true, text: "Polynesian", value: "H" },
      { selected: true, text: "Middle Eastern", value: "I" },
      { selected: true, text: "Jewish", value: "J" },
      { selected: true, text: "Western European", value: "K" },
      { selected: true, text: "Eastern European", value: "L" },
      { selected: true, text: "Other Groups", value: "O" },
      { selected: true, text: "Hispanic", value: "Y" },
    ],
  },
  getters: {
    advisors: (state) => state._advisors,
    advisor_clients: (state) => (agent: string) => {
      console.log(
        "getting advisor clients",
        agent,
        state._advisor_clients,
        state._advisor_clients[agent]
      );
      return state._advisor_clients[agent] || [];
    },
    clients: (state) => state._clients,
    annuities: (state) => state._annuities,
    snapshots: (state) => state._snapshots,
    morningstar: (state) => state._morningstar,
    morningstar_va: (state) =>
      state._morningstar.filter((x) => x.annuity_type == "va"),
    morningstar_fia: (state) =>
      state._morningstar.filter((x) => x.annuity_type == "fia"),
    morningstar_fia_carriers: (state) =>
      state._morningstar
        .filter((x) => x.annuity_type == "fia")
        .map((x) => x.company_name)
        .sort(),
    morningstar_va_carriers: (state) =>
      state._morningstar
        .filter((x) => x.annuity_type == "va")
        .map((x) => x.company_name)
        .sort(),
    contract_loading: (state) =>
      state._clients_loading ||
      state._annuities_loading ||
      state._snapshots_loading,
  },
  mutations: {
    set_search_type(state, type) {
      state.create_seminar.final_search_type = type;
    },
    reset_create_seminar(state) {
      state.create_seminar = {
        type: "Seminar",
        step: 1,
        logo: "none",
        pricing: 0.52,
        campaign_id: null, // temp 1670
        venueName: null,
        venueAddress: null, // used for autocomplete state
        street: null,
        city: null,
        state: null,
        zip: null, // THIS IS THE VENUE ZIP CODE
        range: 6,
        search_type: "radius", // zip or radius
        final_search_type: "unset",
        lat: 0,
        lng: 0,
        zips: [],
        email_disclosure: "",
        book_disclosure: "",
        appointment_dates: [],
        restaurantValue: null,
        min_event_date_offset: 1000 * 60 * 60 * 24 * 28,
        events: [
          {
            date: new Date(Date.now() + 1000 * 60 * 60 * 24 * 28)
              .toISOString()
              .slice(0, 10),
            time: "6:30 pm",
            capacity: null,
            aptDate1: null,
            aptDate2: null,
            aptDate3: null,
            aptDate4: null,
          },
        ],
        contentTemplate: null,
        contentTemplateId: 60,
        background: "Steak Dinner",
        final_price: 0,
        demographics: {
          distance: 30,
          income: 50000,
          income_type: "income",
          search_query: "",
          ipa: null,
          minAge: 65,
          maxAge: 79,
          platelicker_enabled: false,
          number_of_records: 0,
          requested_count: 0,
          request_id: null,
          ethnicityChanged: false,
          ethnicityList: [
            { selected: true, text: "African American", value: "A" },
            { selected: true, text: "East Asian", value: "B" },
            { selected: true, text: "Central & Southwest Asian", value: "D" },
            { selected: true, text: "Mediterranean", value: "E" },
            { selected: true, text: "Native American", value: "F" },
            { selected: true, text: "Scandinavian", value: "G" },
            { selected: true, text: "Polynesian", value: "H" },
            { selected: true, text: "Middle Eastern", value: "I" },
            { selected: true, text: "Jewish", value: "J" },
            { selected: true, text: "Western European", value: "K" },
            { selected: true, text: "Eastern European", value: "L" },
            { selected: true, text: "Other Groups", value: "O" },
            { selected: true, text: "Hispanic", value: "Y" },
            // ### OLD ###
            // { selected: true, text: "African", value: "D" },
            // { selected: true, text: "African American", value: "B" },
            // { selected: true, text: "Native American", value: "I" },
            // { selected: true, text: "Arab", value: "E" },
            // { selected: true, text: "Asian", value: "A" },
            // { selected: true, text: "Caucasian", value: "W" },
            // { selected: true, text: "Chinese", value: "C" },
            // { selected: true, text: "Dutch", value: "L" },
            // { selected: true, text: "Eastern European", value: "S" },
            // { selected: true, text: "Egyptian", value: "F" },
            // { selected: true, text: "English", value: "O" },
            // { selected: true, text: "European", value: "Q" },
            // { selected: true, text: "Greek", value: "G" },
            // { selected: true, text: "Hispanic", value: "H" },
            // { selected: true, text: "Indian", value: "K" },
            // { selected: true, text: "Japanese", value: "N" },
            // { selected: true, text: "Jewish", value: "J" },
            // { selected: true, text: "East Asian", value: "R" },
            // { selected: true, text: "Portuguese", value: "P" },
            // { selected: true, text: "White Non-American", value: "M" },
          ],
        },
        language: null,
      };
    },
    create_seminar_address(state, payload) {
      state.create_seminar.street = payload.name;
      state.create_seminar.city = payload.locality;
      state.create_seminar.state = payload.administrative_area_level_1;
      state.create_seminar.zip = payload.postal_code;
      state.create_seminar.lat = payload.latitude;
      state.create_seminar.lng = payload.longitude;
      console.log(payload);
    },

    set_advisors(state, payload) {
      state._advisors = payload;
    },
    set_advisors_clients(state, payload) {
      state._advisor_clients[payload.advisor_uid] = payload.clients;
    },
    set_marketer_users(state, payload) {
      state._users_loading = true;
      state._users = payload;
      state._users_loading = false;
    },
    set_clients(state, payload) {
      state._clients_loading = true;
      state._clients = payload;
      state._clients_loading = false;
    },
    set_annuities(state, payload) {
      state._annuities_loading = true;
      state._annuities = payload;
      state._annuities_loading = false;
    },
    set_snapshots(state, payload) {
      state._snapshots_loading = true;
      state._snapshots = payload;
      state._snapshots_loading = false;
    },
    set_morningstar(state, payload) {
      state._morningstar_loading = true;
      state._morningstar = payload;
      state._morningstar_loading = false;
    },
    initQuiz(state, payload) {
      state.quiz_meta.current_quiz = payload;
      state.quiz_meta.current_question = 0;
      state.quiz_state = {
        // Page 1 - Client Questions
        first_name: "",
        last_name: "",
        dob: "",
        email: "",
        cid: "",
        uid: "",
        phone: "",
        state: "",
        notes: "",

        _dob_radio: "dob",
        _dob_day: "",
        _dob_month: "",
        _dob_year: "",
        _select_or_create: "select",

        // Page 2 - Carrier & Product Data
        carrier: "",
        product: "",
        annuity_type: "Non-Qualified",
        asset_type: "va",
        policy_number: "",

        // Page 3 - Fees & Charges
        guaranteed_income: false,
        has_death_benefit: false,
        me_fee: "",
        admin_fee: "",
        expense_ratio: "",
        income_rider_fee: "",
        other_fee: "",
        income_start_age: "",
        income_rider_amount: "",

        // Page 4 - Key Dates
        statement_date: "",
        _statement_day: "",
        _statement_month: "",
        _statement_year: "",
        issue_date: "",
        _issue_day: "",
        _issue_month: "",
        _issue_year: "",
        surrender_years: "",

        // Page 5 -Deposits & Withdrawals
        initial_deposit: null as number | null,
        total_additional_deposits: 0 as number | null,
        total_withdrawals: 0 as number | null,

        // Page 6 - Account Values
        current_value: null as number | null,
        mva: false,
        surrender_value: null as number | null,

        // new-statement
        additional_deposits: null as number | null,
        withdrawals: null as number | null,
      };
    },
    set_snackbar(state, payload) {
      state.snackbar.show = true;
      state.snackbar.text = payload;
      state.snackbar.timeout = 5000;
    },
    set_snackbar_long(state, payload) {
      state.snackbar.show = true;
      state.snackbar.text = payload;
      state.snackbar.timeout = 10000;
    },
    updateUser(state, payload) {
      state.user = {
        ...state.user,
        ...payload,
      };
    },

    setUser(state, user: User) {
      if (user === null) {
        state.user = {
          is_login: false,
          acl: 0,
          uid: "",
          first_name: "",
          last_name: "",
          email: "",
          subscription_id: "",
        };
      } else {
        state.user = user;
        state.user.is_login = true;
      }
    },
    nextQuestion(state) {
      if (
        state.quiz_meta.current_question <
        (state as any)[state.quiz_meta.current_quiz].length - 1
      ) {
        state.quiz_meta.current_question++;
      }
    },
    previousQuestion(state) {
      if (state.quiz_meta.current_question > 0) {
        state.quiz_meta.current_question--;
      }
    },
  },
  actions: {
    async get_advisors({ state, commit }) {
      // wait for user to load
      await state.user_loading;

      if (state.user.acl <= 5) {
        console.log("user is not a marketer");
        return [];
      }
      if (state._advisors.length > 0) {
        console.log("returning cached advisors");
        // return cached advisors
        return state._advisors;
      }

      if (state.user.acl == 10) {
        // get all users
        console.log("getting all users");
        const q = query(collection(db, "users"));
        const querySnapshot = await getDocs(q);
        const advisors: any[] = [];
        querySnapshot.forEach((doc) => {
          advisors.push({ ...doc.data(), uid: doc.id });
        });
        commit("set_advisors", advisors);
        return advisors.sort((a, b) => {
          return a.first_name > b.first_name ? 1 : -1;
        });
      }

      console.log("getting advisors from firebase");
      const q = query(
        collection(db, "users"),
        where("marketer", "==", state.user.uid)
      );
      const querySnapshot = await getDocs(q);
      const advisors: any[] = [];
      querySnapshot.forEach((doc) => {
        console.log(doc.id, " => ", doc.data());
        advisors.push({ ...doc.data(), uid: doc.id });
      });

      commit("set_advisors", advisors);
      return advisors;
    },
    async get_advisors_clients({ state, commit }, advisor_uid) {
      console.log("get_advisors_clients", advisor_uid);
      if (state.user.acl <= 5) {
        console.log("user is not a marketer");
        return [];
      }
      if (!advisor_uid) {
        console.log("no advisor uid provided");
        return [];
      }
      if (state._advisor_clients[advisor_uid]) {
        console.log("returning cached advisors");
        // return cached advisors
        return state._advisor_clients[advisor_uid];
      }

      console.log("getting clients from firebase");
      const q = query(
        collection(db, "clients"),
        where("uid", "==", advisor_uid)
      );
      const querySnapshot = await getDocs(q);
      const clients: any[] = [];
      querySnapshot.forEach((doc) => {
        clients.push({ ...doc.data(), id: doc.id });
      });
      commit("set_advisors_clients", { advisor_uid, clients });
      return clients;
    },
  },
  modules: {},
});

export default store;
