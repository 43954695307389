import { RouteConfig } from "vue-router";

export default [
  {
    path: "/admin/sync",
    name: "admin_sync",
    component: () =>
      import(
        /* webpackChunkName: "admin_sync" */ "../views/admin/AdminSync.vue"
      ),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      title: "Sync | Admin",
    },
  },
  {
    path: "/admin/zlt",
    name: "admin_zlt",
    component: () =>
      import(
        /* webpackChunkName: "admin_zlt_dashboard" */ "../views/admin/AdminEZLT.vue"
      ),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      title: "ZLT | Admin",
    },
  },
  {
    path: "/admin/annuities",
    name: "admin_annuities",
    component: () =>
      import(
        /* webpackChunkName: "admin_annuities_dashboard" */ "../views/admin/AdminAnnuityDashboard.vue"
      ),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      title: "Annuities | Admin",
    },
  },

  {
    path: "/admin/annuity/:id",
    name: "admin_annuity",
    component: () =>
      import(
        /* webpackChunkName: "admin_annuity_view" */ "../views/admin/AdminAnnuityView.vue"
      ),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      title: "Annuity | Admin",
    },
  },
  {
    path: "/admin/dashboard/:id",
    name: "admin_dashboard",
    component: () =>
      import(
        /* webpackChunkName: "admin_dashboard_view" */ "../views/admin/AdminDashboardView.vue"
      ),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      title: "Dashboard | Admin",
    },
  },
  {
    path: "/admin/advisors",
    name: "AdminAgentsView",
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      title: "Advisors | Admin",
    },
    component: () =>
      import(
        /* webpackChunkName: "admin_advisors" */ "../views/admin/AgentsView.vue"
      ),
  },

  {
    path: "/admin/analytics",
    name: "AdminAnalyticsView",
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      title: "Analytics | Admin",
    },
    component: () =>
      import(
        /* webpackChunkName: "admin_analytics" */ "../views/admin/AnalyticsView.vue"
      ),
  },
  {
    path: "/admin/projection",
    name: "AdminProjectionView",
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      title: "Projection | Admin",
    },
    component: () =>
      import(
        /* webpackChunkName: "admin_projection" */ "../views/admin/ProjectionView.vue"
      ),
  },
  {
    path: "/admin/imports",
    name: "AdminImportsView",
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      title: "Imports | Admin",
    },
    component: () =>
      import(
        /* webpackChunkName: "admin_imports" */ "../views/admin/ImportsView.vue"
      ),
  },
  {
    path: "/enterprise",
    name: "AdminEnterpriseView",
    meta: {
      requiresAuth: true,
      // requiresAdmin: true,
      title: "Enterprise Dashboard",
    },
    component: () =>
      import(
        /* webpackChunkName: "enterprise" */ "../views/admin/EnterpriseView.vue"
      ),
  },
] as RouteConfig[];
