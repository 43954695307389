import {
  collection,
  query,
  where,
  onSnapshot,
  getDocs,
} from "firebase/firestore";
import { db, storage } from "@/firebaseConfig";
import { Client, Annuity, Snapshot, User } from "@/models";
import { ref, getDownloadURL } from "firebase/storage";

export function watcher(store: any) {
  // if (store.state.user.acl == 5) {
  //   //load all users where their marketer is the current user
  //   let mq = query(
  //     collection(db, "users"),
  //     where("marketer", "==", store.state.user.uid)
  //   );
  //   let unsubUsers = onSnapshot(mq, (querySnapshot) => {
  //     const users = [] as User[];
  //     querySnapshot.forEach((doc) => {
  //       users.push({
  //         uid: doc.id,
  //         ...doc.data(),
  //       } as User);
  //     });
  //     store.commit("set_marketer_users", users);
  //   });
  // }

  // store.state.contracts_loading_p = new Promise((resolve, reject) => {});

  let cq = query(
    collection(db, "clients"),
    where("uid", "==", store.state.user.uid)
  );
  let unsubClients = onSnapshot(cq, (querySnapshot) => {
    const clients = [] as Client[];
    querySnapshot.forEach((doc) => {
      clients.push({
        id: doc.id,
        ...doc.data(),
      } as Client);
    });
    store.commit("set_clients", clients);
  });

  let aq = query(
    collection(db, "annuities"),
    where("uid", "==", store.state.user.uid)
  );

  let unsubAnnuities = onSnapshot(aq, (querySnapshot) => {
    const annuities = [] as Annuity[];
    querySnapshot.forEach((doc) => {
      annuities.push({
        id: doc.id,
        ...doc.data(),
      } as Annuity);
    });
    store.commit("set_annuities", annuities);
  });

  let sq = query(
    collection(db, "snapshots"),
    where("uid", "==", store.state.user.uid)
  );

  let unsubSnapshot = onSnapshot(sq, (querySnapshot) => {
    const snapshots = [] as Snapshot[];
    querySnapshot.forEach((doc) => {
      snapshots.push({
        id: doc.id,
        ...doc.data(),
      } as Snapshot);
    });
    store.commit("set_snapshots", snapshots);
  });

  if (localStorage.getItem("morningstar") !== null) {
    const ms = JSON.parse(localStorage.getItem("morningstar") || "{}");
    if (ms.timestamp > Date.now() - 604800000) {
      store.commit("set_morningstar", ms.data);
    }
  }

  if (store.state._morningstar.length === 0) {
    getDownloadURL(ref(storage, "main.json")).then((url) => {
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          localStorage.setItem(
            "morningstar",
            JSON.stringify({ timestamp: Date.now(), data: data })
          );
          store.commit("set_morningstar", data);
        });
    });
  }

  return () => {
    unsubClients();
    unsubAnnuities();
    unsubSnapshot();
  };
}
