
import AOS from "aos";
import "aos/dist/aos.css";
import Vue from "vue";
import VideoPlayer from "@/components/VideoPlayer.vue";

export default Vue.extend({
  mounted() {
    AOS.init({
      disable: "mobile",
    });
  },
  components: {
    VideoPlayer,
  },
  data() {
    return {
      dialog: false,
      videoPaused: true,
      videoOptions: {
        autoplay: false,
        controls: true,
        sources: [
          {
            src: "https://videodelivery.net/4ec51b4e7241a6b5ec39ca2a6e3c98c0/manifest/video.m3u8?clientBandwidthHint=1000",
            type: "application/x-mpegURL",
          },
        ],
      },
    };
  },

  watch: {
    dialog(newVal: boolean, oldVal: boolean) {
      this.videoPaused = !newVal;
    },
  },
  methods: {
    // async getHLSStream(id: string) {
    //   const url = `https://videodelivery.net/${id}`;
    //   const res = await fetch(`${url}/manifest/video.m3u8`);

    //   const streamText = await res.text();

    //   const parts = /#EXT-X-STREAM-INF:RESOLUTION=(.*)\n(.*)/gm.exec(
    //     streamText
    //   );
    //   //correct error of parts being possibly null
    //   if (parts === null) {
    //     return "";
    //   }
    //   return `${url}/manifest/${parts[2]}`;
    // },

    loggie() {
      console.log(this.$store.state.cart.been_to_pricing);
    },
    watchDemo() {
      this.dialog = true;
    },
    goTo(url: string) {
      this.$router.push(url);
    },
  },
});
