<template>
  <div>
    <v-text-field
      v-bind="{ ...$attrs, ...commonAttrs }"
      v-on="$listeners"
      class="field"
    >
      <template
        v-for="(_, scopedSlotName) in $scopedSlots"
        #[scopedSlotName]="slotData"
      >
        <slot :name="scopedSlotName" v-bind="slotData" />
      </template>
      <template v-for="(_, slotName) in $slots" #[slotName]>
        <slot :name="slotName" />
      </template>
    </v-text-field>
  </div>
</template>

<style scoped>
.v-text-field--prefix {
  color: rgba(255, 255, 255, 0.6) !important;
}
.v-input__control {
  color: rgba(255, 255, 255, 1) !important;
}

::v-deep .v-text-field__prefix.primary--text {
  color: rgb(255, 0, 0) !important;
  caret-color: rgb(255, 0, 0) !important;
}
</style>

<script>
export default {
  inheritAttrs: false,
  computed: {
    commonAttrs() {
      return {
        // outlined: true,
        dense: true,
        required: true,
        solo: true,
        hideDetails: true,
      };
    },
  },
};
</script>
