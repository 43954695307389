import { RouteConfig } from "vue-router";

export default [
  {
    path: "/marketer/annuities",
    name: "marketer_annuities",
    component: () =>
      import(
        /* webpackChunkName: "marketer_MarketerDashboardView" */ "../views/marketer/MarketerDashboardView.vue"
      ),
    meta: {
      title: "Annuities | Marketer",
      requiresAuth: true,
    },
  },
  {
    path: "/marketer/advisors",
    name: "MarketerAgentsView",
    meta: {
      requiresAuth: true,
      title: "Advisors | Marketer",
    },
    component: () =>
      import(
        /* webpackChunkName: "marketers_AgentsView" */ "../views/marketer/AgentsView.vue"
      ),
  },
  {
    path: "/marketer/advisor/:uid",
    name: "MarketerAgentDashboardView",
    meta: {
      requiresAuth: true,
      title: "Advisor | Marketer",
    },
    component: () =>
      import(
        /* webpackChunkName: "MarketerAgentDashboardView" */ "../views/marketer/AgentDashboardView.vue"
      ),
  },

  {
    path: "/marketer/annuity/:id",
    name: "marketer_annuity",
    component: () =>
      import(
        /* webpackChunkName: "marketer_annuity_view" */ "../views/admin/AdminAnnuityView.vue"
      ),
    meta: {
      requiresAuth: true,
      title: "Annuity | Marketer",
    },
  },
] as RouteConfig[];
