import { RouteConfig } from "vue-router";

export default [
  {
    path: "/enterprise/annuity/:id",
    name: "enterprise_annuity",
    component: () =>
      import(
        /* webpackChunkName: "EnterpriseAnnuityView" */ "../views/EnterpriseAnnuityView.vue"
      ),
    meta: {
      title: "AnnuityScore | Enterprise",
      requiresAuth: true,
    },
  },
  {
    path: "/enterprise/agents",
    name: "enterprise_agents",
    component: () =>
      import(
        /* webpackChunkName: "EnterpriseAgentsView" */ "../views/admin/EnterpriseAgentsView.vue"
      ),
    meta: {
      title: "Enterprise | Agents",
      requiresAuth: true,
    },
  },
] as RouteConfig[];
