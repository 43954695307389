import { RouteConfig } from "vue-router";

export default [
  {
    path: "/account",
    name: "account",
    meta: {
      requiresAuth: true,
      title: "Account",
    },
    component: () =>
      import(/* webpackChunkName: "account" */ "../views/AccountView.vue"),
  },
  {
    path: "/terms",
    name: "terms",
    meta: {
      requiresAuth: false,
      title: "Terms & Conditions",
    },
    component: () =>
      import(/* webpackChunkName: "terms" */ "../views/TermsView.vue"),
  },
  {
    path: "/new-annuity/:uid?",
    name: "NewAnnuityView",
    meta: {
      requiresAuth: true,
      requiresSub: true,
      title: "New Annuity",
    },
    component: () =>
      import(
        /* webpackChunkName: "new_annuity" */ "../views/NewAnnuityView.vue"
      ),
  },
  {
    path: "/annuity-reloaded/:aid",
    name: "AnnuityReloadedView",
    meta: {
      requiresAuth: true,
      requiresSub: true,
      title: "New Annuity",
    },
    component: () =>
      import(
        /* webpackChunkName: "new_annuity" */ "../views/NewAnnuityView.vue"
      ),
  },
  {
    path: "/annuity/:id",
    name: "AnnuityView",
    meta: {
      requiresAuth: true,
      requiresSub: true,
      title: "Annuity",
    },
    component: () =>
      import(/* webpackChunkName: "annuity" */ "../views/AnnuityView.vue"),
  },
  {
    path: "/new-statement/:id",
    name: "NewStatementView",
    meta: {
      requiresAuth: true,
      requiresSub: true,
      title: "New Statement",
    },
    component: () =>
      import(
        /* webpackChunkName: "new_statement" */ "../views/NewStatementView.vue"
      ),
  },

  {
    path: "/dashboard",
    name: "Dashboard",
    meta: {
      requiresAuth: true,
      requiresSub: true,
      title: "Dashboard",
    },
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/DashboardView.vue"),
  },
  {
    path: "/calculators",
    name: "Calculators",
    meta: {
      requiresAuth: true,
      requiresSub: true,
      title: "Calculators",
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/CalculatorsView.vue"
      ),
  },
  {
    path: "/calculators/max-withdrawals",
    name: "Max Withdrawals",
    meta: {
      requiresAuth: true,
      requiresSub: true,
      title: "Max Withdrawals",
    },
    component: () =>
      import(
        /* webpackChunkName: "max_withdrawals" */ "../views/calculators/MaxWdView.vue"
      ),
  },
  {
    path: "/calculators/illustrate_withdrawal_amount",
    name: "Illustrate Withdrawals",
    meta: {
      requiresAuth: true,
      requiresSub: true,
      title: "Illustrate Withdrawals",
    },
    component: () =>
      import(
        /* webpackChunkName: "illustrate_withdrawal_amount" */ "../views/calculators/WithdrawalsView.vue"
      ),
  },
  {
    path: "/calculators/solve_for_minimum_interest",
    name: "Solve for IRR",
    meta: {
      requiresAuth: true,
      requiresSub: true,
      title: "Solve for IRR",
    },
    component: () =>
      import(
        /* webpackChunkName: "solve_for_minimum_interest" */ "../views/calculators/MinInterestView.vue"
      ),
  },
  {
    path: "/calculators/solve_for_minimum_deposit",
    name: "Minimum Deposit",
    meta: {
      requiresAuth: true,
      requiresSub: true,
      title: "Minimum Deposit",
    },
    component: () =>
      import(
        /* webpackChunkName: "solve_for_minimum_deposit" */ "../views/calculators/MinDepositView.vue"
      ),
  },
  // {
  //   path: "/marketing",
  //   name: "MarketingView",
  //   meta: {
  //     title: "Marketing",
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "marketing" */ "../views/MarketingView.vue"),
  // },
  // {
  //   path: "/internal",
  //   name: "MarketingLateView",
  //   meta: {
  //     title: "Marketing",
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "marketing" */ "../views/MarketingView.vue"),
  // },
  // {
  //   path: "/marketing/purchase_books",
  //   name: "PurchaseBooksView",
  //   meta: {
  //     requiresAuth: true,
  //     title: "Purchase Books | Marketing",
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "marketing_purchase_books" */ "../views/marketing/PurchaseBooksView.vue"
  //     ),
  // },
  // {
  //   path: "/marketing/order_history",
  //   name: "OrderHistoryView",
  //   meta: {
  //     requiresAuth: true,
  //     title: "Order History | Marketing",
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "marketing_order_history" */ "../views/marketing/OrderHistoryView.vue"
  //     ),
  // },
  // {
  //   path: "/marketing/view_campaigns",
  //   name: "ViewCampaignsView",
  //   meta: {
  //     requiresAuth: true,
  //     title: "View Campaigns | Marketing",
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "marketing_view_campaigns" */ "../views/marketing/ViewCampaignsView.vue"
  //     ),
  // },
  // {
  //   path: "/marketing/view_campaigns/:id",
  //   name: "CampaignDetailsView",
  //   meta: {
  //     requiresAuth: true,
  //     title: "Campaign Details | Marketing",
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "campaign_details" */ "../views/marketing/CampaignDetailsView.vue"
  //     ),
  // },
  // {
  //   path: "/marketing/create_campaign",
  //   name: "CreateCampaignView",
  //   meta: {
  //     requiresAuth: true,
  //     title: "Campaign | Marketing",
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "marketing_create_campaign" */ "../views/marketing/CreateCampaignView.vue"
  //     ),
  // },
  // {
  //   path: "/marketing/create_campaign/thank-you",
  //   name: "ThankYouView",
  //   meta: {
  //     requiresAuth: true,
  //     title: "Campaign | Marketing",
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "campaign_thank_you_view" */ "../views/marketing/ThankYouView.vue"
  //     ),
  // },
  // {
  //   path: "/marketing/leads",
  //   name: "LeadsView",
  //   meta: {
  //     requiresAuth: true,
  //     title: "Leads | Marketing",
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "marketing_leads" */ "../views/marketing/LeadsView.vue"
  //     ),
  // },
  // {
  //   path: "/marketing/leads/:id",
  //   name: "LeadDetailView",
  //   meta: {
  //     requiresAuth: true,
  //     title: "Lead | Marketing",
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "lead" */ "../views/marketing/LeadDetailView.vue"
  //     ),
  // },
] as RouteConfig[];
